<div
  class="au-home-section"
  *ngIf="urlService.checkUrlCom()"
  [class.us-home-section]="urlService.checkUrlCo()"
>
  <section class="hero panel" (scroll)="onWindowScroll($event)">
    <div class="container container-hero d-flex">
      <div>
        <h1
          id="hero-header-wrapper"
          class="hero__header hero__header_height mb-20"
        >
          <span
            [innerHTML]="heroHeaderText"
            id="hero-header"
            class="typing-element animate__animated animate__fadeIn"
          ></span>
        </h1>
        <p
          id="hero-text"
          class="hero__text animate__animated animate__fadeIn mb-30"
          [innerHTML]="heroSecondaryText"
        ></p>
        <a
          href="https://portal.plexe.com.au/public"
          class="btn btn-orange"
          style="margin: auto"
          *ngIf="urlService.checkUrlCom(); else domainIsCo"
          >I'm interested</a
        >
        <ng-template #domainIsCo>
          <a href="#shareDetails" class="btn btn-orange">I'm interested</a>
        </ng-template>
      </div>
      <div class="mt-30" *ngIf="showLaunchPanel">
        <h3 class="register__header animate__animated animate__fadeIn mb-20">
          Get Funded in Minutes
        </h3>
        <p
          id="hero-text"
          class="
            hero__text
            animate__animated animate__fadeIn
            mb-10
            register__description
          "
        >
          See how much funding your eligible for with no credit check of
          commitment to proceed.
        </p>
        <form
          class="form mt-50"
          [formGroup]="registerForm"
          (submit)="register()"
        >
        <div class="form-group">
          <input
            type="text"
            formControlName="firstName"
            class="form-input mt-10"
            placeholder="Name"
          />
          <input
            UsPhoneMask
            formControlName="phoneNumber"
            type="text"            
            class="form-input mt-10"
            maxlength="14"
            placeholder="Phone number"
            placeholder="(___) ___-____"
            required 
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            formControlName="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            class="form-input mt-10"
            placeholder="Email"
          />
          <input
            type="password"
            formControlName="password"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
            class="form-input mt-10"
            placeholder="Password"
          />
        </div>
        <div class="form-group">
          <select class="form-input mt-10" formControlName="yearsTrading">
                <option value="" disabled selected>Years in Business</option>
                <option *ngFor="let tradingYear of tradingYears" value="{{ tradingYear }}">
                  {{ tradingYear }}
                </option>
              </select>
			  
          <input
            type="text"
            formControlName="zipcode"
            inputmode="numeric" 
            maxlength="5"
            pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
            class="form-input mt-10"
            placeholder="Zipcode"
          />
        </div>


          <button
            type="submit"
            [disabled]="pendingRegisterForm"
            class="btn btn-orange mt-30"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </section>
  <section class="limits panel">
    <div class="container d-flex">
      <div>
        <h1 class="limits__header">
          A revolving credit line that automatically grows with you
        </h1>
        <p class="limits__text">
          Consolidate all your working capital requirements into one solution
          and receive the best terms based on your business performance now and
          into the future <br /><br />
          Funding lines from $10 000 to $1,000,000 that enable you to redraw
          anytime
        </p>
        <a
          href="https://portal.plexe.com.au/public"
          class="btn btn-orange"
          *ngIf="urlService.checkUrlCom(); else domainIsCo"
          >I'm interested</a
        >
        <ng-template #domainIsCo>
          <a href="#shareDetails" class="btn btn-orange">I'm interested</a>
        </ng-template>
      </div>
    </div>
  </section>

  <section class="why-us panel">
    <div class="container why-us-container d-flex">
      <div>
        <h1 class="why-us__header">Why us?</h1>
        <ul>
          <li>
            <h4 class="why-us__subheader">A fast response</h4>
            <p class="why-us__text">
              Find out our maximum borrowing capacity in minutes with no
              application or credit checks
            </p>
          </li>

          <li>
            <h4 class="why-us__subheader">Simplify your facilities into one</h4>
            <p class="why-us__text">
              Remove complexities with managing multiple lending products and
              access one lending solution
            </p>
          </li>

          <li>
            <h4 class="why-us__subheader">
              Select from a variety of repayment options
            </h4>
            <p class="why-us__text">
              Includes fixed term, choosing the percentage of your income to put
              towards your repayments or match your customer invoice payments
              with flexibility to change any time
            </p>
          </li>

          <li>
            <h4 class="why-us__subheader">Simple and transparent</h4>
            <p class="why-us__text why-us__text_last">
              We only charge one fee on your repayment. There are no hidden
              fees, no management fees, line fees or even interest
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="ceo-quote panel">
    <div class="container ceo-quote-container d-flex">
      <div>
        <h3 class="mb-50">
          “We needed to fund our expansion but had been let down by the banks
          and other lenders to the point of not even being contacted back once
          we started the process. All of the above couldn’t have been more
          different with Plexe.”
        </h3>
      </div>
    </div>
  </section>
  <section class="section-block panel">
    <div
      class="swiper-container"
      [swiper]="howitworks"
      [(index)]="howItWorksCurrentSlide"
    >
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <section class="how-it-works how-it-works-1" id="how-it-works-1">
            <div class="container">
              <h1 class="how-it-works__header">How it works</h1>
              <h4 class="home__subheader how-it-works__subheader">
                A fast and simple process
              </h4>
              <p>
                Connect your account software and/or bank account details to
                find out your maximum borrowing capacity immediately. There are
                no credit checks or application forms. We do not store your
                login credentials.
              </p>
            </div>
          </section>
        </div>
        <div class="swiper-slide">
          <section class="how-it-works how-it-works-1" id="how-it-works-2">
            <div class="container">
              <h1 class="how-it-works__header">How it works</h1>
              <h4 class="home__subheader how-it-works__subheader">
                Access funds 24/7
              </h4>
              <p>
                Explore the different repayment options available to you. If you
                choose to proceed, your account set up is completed in minutes.
              </p>
            </div>
          </section>
        </div>
      </div>

      <div class="container container_how-it-works">
        <div class="slider-container slider-container_lightblue mt-40">
          <div class="controls-container">
            <div class="swiper-button-prev slider-control slider-control-prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.089"
                height="13.643"
              >
                <path
                  d="M7.918 12.986c.239-.234.459-.487.707-.711a1.021 1.021 0 000-1.709c-.1-.094-.189-.191-.284-.286-.91-.911-1.819-1.824-2.746-2.718-.035-.034-.045-.059-.075-.091a1.866 1.866 0 01-.257-.309.645.645 0 01-.041-.107.6.6 0 01-.063-.233.585.585 0 01.063-.233.61.61 0 01.041-.107 1.856 1.856 0 01.257-.309c.03-.032.04-.057.075-.091.926-.9 1.834-1.807 2.745-2.718.095-.095.189-.192.285-.286a1.021 1.021 0 000-1.709c-.248-.224-.468-.477-.707-.711-.868-.849-1.271-.88-2.1-.071Q3.151 3.254.485 5.925l-.041.044a2.843 2.843 0 00-.2.231c-.031.042-.063.084-.09.129a1.081 1.081 0 00-.135.355 1.022 1.022 0 00-.017.138 1.043 1.043 0 00.017.138 1.081 1.081 0 00.135.355 1.44 1.44 0 00.09.128 2.57 2.57 0 00.2.233l.039.043q2.667 2.667 5.333 5.338c.833.808 1.236.778 2.1-.071"
                />
              </svg>
            </div>
            <div class="swiper-pagination how-it-works-pagination"></div>
            <div class="swiper-button-next slider-control slider-control-next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.09"
                height="13.643"
              >
                <path
                  d="M1.172.661c-.239.234-.459.487-.707.711a1.021 1.021 0 000 1.709c.1.094.189.191.284.286.91.911 1.819 1.824 2.746 2.718.035.034.045.059.075.091a1.8 1.8 0 01.257.309.622.622 0 01.041.107.6.6 0 01.063.233.59.59 0 01-.063.233.6.6 0 01-.041.107 1.8 1.8 0 01-.257.309 1.175 1.175 0 01-.075.091c-.926.9-1.834 1.807-2.745 2.718-.095.095-.189.192-.285.287a1.02 1.02 0 000 1.708c.248.224.468.477.707.711.868.849 1.271.88 2.1.071q2.667-2.667 5.333-5.338l.041-.044a2.843 2.843 0 00.2-.231c.031-.042.063-.084.09-.129a1.07 1.07 0 00.135-.355.859.859 0 00.017-.138.886.886 0 00-.017-.138 1.07 1.07 0 00-.135-.355 1.515 1.515 0 00-.09-.128 2.566 2.566 0 00-.2-.233l-.039-.043Q5.94 3.261 3.274.59c-.833-.808-1.236-.778-2.1.071"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-block panel">
    <div
      class="swiper-container"
      [swiper]="guide"
      [(index)]="guideCurrentSlide"
    >
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <section class="guide guide-1">
            <div class="container">
              <h1 class="guide__header white">
                Your guide to our line of credit
              </h1>
              <h4 class="home__subheader guide__subheader black">
                How could your business benefit
              </h4>
              <p class="black">
                Most working capital loans require you to withdraw 100% of
                available funds once you’re approved and reapply each time you
                need additional funds for your business. This leaves you with
                little funds available when unexpected opportunities arise.
              </p>
            </div>
          </section>
        </div>

        <div class="swiper-slide">
          <section class="guide guide-2">
            <div class="container">
              <h1 class="guide__header white">
                Your guide to our line of credit
              </h1>
              <h4 class="home__subheader guide__subheader black">
                How could your business benefit
              </h4>
              <p class="black">
                With Plexe your Line of Credit provides you with a limit, which
                is the maximum amount you can withdraw. You can use it as much
                or as little, and as often as you like for business purchases
                such as inventory, supplies, or operating expenses. Each time
                you withdraw, you set the terms you like, which consolidates all
                your past withdrawals. This makes it easy for you to manage only
                one weekly repayment.
              </p>
            </div>
          </section>
        </div>

        <div class="swiper-slide">
          <section class="guide guide-3">
            <div class="container">
              <h1 class="guide__header white">
                Your guide to our line of credit
              </h1>
              <h4 class="home__subheader guide__subheader black">
                How could your business benefit
              </h4>
              <p class="black">
                A key difference between lines of credit and term loans is that
                a line of credit is revolving. This means you can use the funds,
                up to the approved amount, and redraw the repayment you have
                made. And with our proprietary technology, your limits also
                increase as your business grows without the need to reapply,
                leaving you to focus on what you do best - your business.
              </p>
            </div>
          </section>
        </div>

        <div class="swiper-slide">
          <section class="guide guide-4">
            <div class="container">
              <h1 class="guide__header white">
                Your guide to our line of credit
              </h1>
              <h4 class="home__subheader guide__subheader black">
                How could your business benefit
              </h4>
              <p class="black">
                We also recognise that businesses can face situations that
                affect cash flow. This can make it particularly difficult when
                you have fixed loan repayments. That is why we let you choose
                between fixed repayments or a percentage of your weekly income,
                with the added option of being able to switch between the two
                over time to match your needs.
              </p>
            </div>
          </section>
        </div>
      </div>

      <div class="container container_guide">
        <div class="slider-container slider-container_black mt-40">
          <div class="controls-container">
            <div
              class="
                swiper-button-prev
                slider-control
                slider-control-prev_guide
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.089"
                height="13.643"
              >
                <path
                  d="M7.918 12.986c.239-.234.459-.487.707-.711a1.021 1.021 0 000-1.709c-.1-.094-.189-.191-.284-.286-.91-.911-1.819-1.824-2.746-2.718-.035-.034-.045-.059-.075-.091a1.866 1.866 0 01-.257-.309.645.645 0 01-.041-.107.6.6 0 01-.063-.233.585.585 0 01.063-.233.61.61 0 01.041-.107 1.856 1.856 0 01.257-.309c.03-.032.04-.057.075-.091.926-.9 1.834-1.807 2.745-2.718.095-.095.189-.192.285-.286a1.021 1.021 0 000-1.709c-.248-.224-.468-.477-.707-.711-.868-.849-1.271-.88-2.1-.071Q3.151 3.254.485 5.925l-.041.044a2.843 2.843 0 00-.2.231c-.031.042-.063.084-.09.129a1.081 1.081 0 00-.135.355 1.022 1.022 0 00-.017.138 1.043 1.043 0 00.017.138 1.081 1.081 0 00.135.355 1.44 1.44 0 00.09.128 2.57 2.57 0 00.2.233l.039.043q2.667 2.667 5.333 5.338c.833.808 1.236.778 2.1-.071"
                />
              </svg>
            </div>
            <div class="swiper-pagination how-it-works-pagination"></div>
            <div
              class="
                swiper-button-next
                slider-control
                slider-control-next_guide
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.09"
                height="13.643"
              >
                <path
                  d="M1.172.661c-.239.234-.459.487-.707.711a1.021 1.021 0 000 1.709c.1.094.189.191.284.286.91.911 1.819 1.824 2.746 2.718.035.034.045.059.075.091a1.8 1.8 0 01.257.309.622.622 0 01.041.107.6.6 0 01.063.233.59.59 0 01-.063.233.6.6 0 01-.041.107 1.8 1.8 0 01-.257.309 1.175 1.175 0 01-.075.091c-.926.9-1.834 1.807-2.745 2.718-.095.095-.189.192-.285.287a1.02 1.02 0 000 1.708c.248.224.468.477.707.711.868.849 1.271.88 2.1.071q2.667-2.667 5.333-5.338l.041-.044a2.843 2.843 0 00.2-.231c.031-.042.063-.084.09-.129a1.07 1.07 0 00.135-.355.859.859 0 00.017-.138.886.886 0 00-.017-.138 1.07 1.07 0 00-.135-.355 1.515 1.515 0 00-.09-.128 2.566 2.566 0 00-.2-.233l-.039-.043Q5.94 3.261 3.274.59c-.833-.808-1.236-.778-2.1.071"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="learn panel" id="shareDetails">
    <div class="container">
      <div>
        <h1 class="learn__header">Want to learn more?</h1>
        <h4 class="home__subheader learn__subheader">
          <ng-container *ngIf="urlService.checkUrlCo()">
            We are currently building our beta application. Join the queue and
            be one of the first to receive access.
            <br />
          </ng-container>
          Leave your details below and we will get in touch to help you find a
          solution that meets your needs.
        </h4>
        <form
          class="form"
          [formGroup]="shareDetailsForm"
          (submit)="shareDetails()"
        >
          <div class="form-group">
            <input
              type="text"
              formControlName="firstName"
              class="form-input mt-10"
              placeholder="First name"
            />
            <input
              type="text"
              formControlName="lastName"
              class="form-input mt-10"
              placeholder="Last name"
            />
          </div>
          <input
            type="email"
            formControlName="email"
            class="form-input mt-10"
            placeholder="Email address"
          />
          <input
            type="text"
            formControlName="companyName"
            class="form-input mt-10"
            placeholder="Company name"
          />
          <input
          UsPhoneMask
            type="text"
            formControlName="phoneNumber"
            class="form-input mt-10"
            placeholder="Phone number"
          />
          <button
            type="submit"
            [disabled]="pendingShareDetails"
            class="btn btn-orange mt-40"
          >
            Share details
          </button>
        </form>
      </div>
    </div>
  </section>
</div>

<div class="us-home-section" *ngIf="urlService.checkUrlCo()">
  <section class="hero panel" (scroll)="onWindowScroll($event)">
    <div class="container container-hero d-flex">
      <div>
        <h1
          id="hero-header-wrapper"
          class="hero__header hero__header_height mb-20"
        >
          <span
            id="hero-header"
            class="typing-element animate__animated animate__fadeIn"
            style="color: rgba(255, 255, 255, 1)"
          >
            An automated Financial
            <span class="super-app-text">Super App</span> for SMEs
          </span>
        </h1>
        <span class="header-divider"></span>
        <p
          id="hero-text-1"
          class="hero__text animate__animated animate__fadeIn mb-30"
        >
          Free registration to access a variety of products and features for
          your business.
        </p>
        <p
          id="hero-text-2"
          class="hero__text animate__animated animate__fadeIn mb-30"
        >
          Includes a unique lending solution that combines all your working
          capital needs, digital credit card, issue corporate cards, financial
          wellness solutions for your employees and tools to help your business
        </p>
        <div class="to-why-us" (click)="scrollToElement(target)">
          <a
            href="https://portal.plexe.com.au/public"
            class="btn btn-orange"
            *ngIf="urlService.checkUrlCom(); else domainIsCo"
            >Find out more</a
          >
          <ng-template #domainIsCo>
            <a
              href="#shareDetails"
              style="font-size: 24px; color: #f16522; font-weight: 700"
              class="find-out-more-link"
              >Find out more</a
            >
          </ng-template>
          <i class="far fa-arrow-alt-circle-down find-out-more"></i>
        </div>
      </div>
    </div>
  </section>
  <section class="limits panel" style="position: relative; top: -1px" id="getstarted">
    <div class="container d-flex">
      <div style="margin: 0 auto; font-size: 24px; color: rgba(1, 11, 50, 1)">
        <h1 class="limits__header">
          A tailored app to unlock the value in your business and maximise its
          potential
        </h1>
        <span class="limits-header-divider"></span>
        <p class="limits__text" style="margin: auto; text-align: center">
          Connect now to see what products are available for you with no credit
          check or commitment to proceed
        </p>
        <!--<a href="https://portal.plexe.com.au/public" class="btn btn-orange" *ngIf="urlService.checkUrlCom(); else domainIsCo">I'm interested</a>
        <ng-template #domainIsCo>
          <a href="#shareDetails" class="btn btn-orange">I'm interested</a>
        </ng-template>-->
        <div style="margin: 0 auto">
          <form
            class="form"
            [formGroup]="sharePartnerDetailsForm"
            (submit)="register()"
            style="margin: 0 auto"
          >
          <div class="form-group">
            <input
              type="text"
              formControlName="firstName"
              class="form-input mt-10"
              placeholder="Name"
            />
            <input
            UsPhoneMask
              type="text"
              formControlName="phoneNumber"
              class="form-input mt-10"
              maxlength="14"
              placeholder="Phone number"
              placeholder="(___) ___-____"
              required 
            />
          </div>
            <div class="form-group">
              <input
                type="email"
                formControlName="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                class="form-input mt-10"
                placeholder="Email"
              />
              <input
                type="password"
                formControlName="password"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
                class="form-input mt-10"
                placeholder="Password"
              />
            </div>
            <div class="form-group">
              <select class="form-input mt-10" formControlName="yearsTrading">
                <option value="" disabled selected>Years in Business</option>
                <option *ngFor="let tradingYear of tradingYears" value="{{ tradingYear }}">
                  {{ tradingYear }}
                </option>
              </select>
			  
              <input
                type="text"
                formControlName="zipcode"
                inputmode="numeric" 
                maxlength="5"
                pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
                class="form-input mt-10"
                placeholder="Zipcode"
              />
            </div>

            <button
              type="submit"
              [disabled]="pendingShareDetails"
              class="btn btn-orange"
              style="margin: 40px auto 60px"
            >
              Submit
            </button>
          </form>
        </div>
        <p *ngIf="partnerDetailSentSuccess">
          Thank you for your interest. We are launching shortly and you are on
          top of the priority queue to receive early access. We will contact you
          shortly.
        </p>
      </div>
    </div>
  </section>

  <!--<section
    class="details-1 panel"
    style="flex-direction: unset; min-height: 500px"
  >
    <div class="d-flex">
      <div class="row" style="margin: 0">
        <div class="section-1 col-md-6">
          <p style="font-size: 32px; color: rgba(1, 11, 50, 1)">
            <img src="../../../assets/svgs/clock.svg" alt="clock" />
          </p>
          <p
            style="
              font-size: 28px;
              font-weight: bold;
              max-width: 240px;
              line-height: 1;
            "
          >
            Issue smart cards in minutes
          </p>
          <p><span class="section-1-divider"></span></p>
          <p>
            Simplify spending and reconcile with ease by capturing receipts and
            providing a direct feed into your accounting software.
          </p>
          <ul
            style="
              font-size: 14px;
              color: rgba(1, 11, 50, 1);
              line-height: 26px;
            "
          >
            <li>No interest or fees for up to 30 days</li>
            <li>Issue for business and for your employees</li>
            <li>
              Pay suppliers immediately through contactless payments or by phone
            </li>
            <li>
              Time-based One-time algorithm changes your card numbers to reduce
              fraud
            </li>
            <li>Add credit to account</li>
            <li>No set up costs</li>
          </ul>
        </div>
        <div class="section-2 col-md-6">
          <p>
            <img src="../../../assets/svgs/book.svg" alt="" />
          </p>
          <p
            style="
              font-size: 28px;
              font-weight: bold;
              max-width: 350px;
              line-height: 1;
            "
          >
            Instantly offer employees a financial wellness app
          </p>
          <span class="section-2-divider"></span>
          <p style="font-size: 16px; font-weight: bold">
            A modern way to increase employee satisfaction by enabling access
            to their earned wage
          </p>
          <ul style="font-size: 14px; line-height: 26px">
            <li>Download the app and earn rewards</li>
            <li>No security from employer or employee</li>
            <li>No interest and no fees</li>
            <li>Integrated into over 70 HR & Payroll software</li>
            <li>Complete automation with no administration</li>
          </ul>
        </div>
      </div>
    </div>
  </section>-->

  <section
    class="detail-2 how-it-works panel"
    (scroll)="onWindowScroll($event)"
  >
    <div class="container container-how-it-works d-flex">
      <div style="margin: 0 auto; text-align: center">
        <h1
          id="how-it-works-header-wrapper"
          class="how-it-works__header how-it-works__header_height mb-20"
        >
          <img src="../../../assets/svgs/clipboard.svg" alt="clipboard" />
        </h1>

        <p
          id="how-it-works-text-1"
          style="margin: 0 auto"
          class="how-it-works__text animate__animated animate__fadeIn mb-30"
        >
          <span> Access a revolving credit line </span>
          <span style="color: white; display: block; margin-top: 15px"
            >that automatically grows with you</span
          >
        </p>
        <span class="detail-2-divider"></span>
        <div class="sub-section">
          <div class="text">
            <p>
              A new lending solution that provides continuously updated,
              repayment terms and funding limits
            </p>
          </div>
          <div class="text">
            <p>
              Select from a variety of repayment options, with transparent fee
              that rewards you on early payment terms
            </p>
          </div>
          <div class="text">
            <p>
              No application forms, view your personalized solution in minutes
              with no credit check
            </p>
          </div>
          <div class="text">
            <p>
              Simplified repayment schedule and flexibility to change your
              preferences at any time
            </p>
          </div>
          <div class="text">
            <p>Limits from $10,000 to $1,000,000</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="why-us detail-3 panel" #target>
    <div class="container why-us-container d-flex">
      <div style="color: white">
        <p class="" style="text-align: center">
          <img src="../../../assets/svgs/checkbox.svg" alt="" />
        </p>
        <h1
          style="
            color: rgba(1, 11, 50, 1);
            text-align: center;
            font-size: 40px;
            font-weight: 600;
            margin: 20px 0px 20px 0px;
          "
        >
          Why us?
        </h1>
        <span class="detail-3-divider" style="margin: 50px auto"></span>
        <div class="d-flex sub-section">
          <div class="why-us-text">
            <p class="detail-3__sub-title">Start using within minutes</p>
            <p style="color: rgba(1, 11, 50, 1); font-size: 16px">
              Registering is free and easy with no credit check or requirement
              to commit. Try all the features before you proceed. No lock in
              contracts
            </p>
          </div>
          <div class="why-us-text">
            <p class="detail-3__sub-title">
              Integrate into your accounting software
            </p>
            <p style="color: rgba(1, 11, 50, 1); font-size: 16px">
              Automated products integrated into one platform and synchronized
              real time. Send information into your accounting software to
              simplify bookkeeping
            </p>
          </div>
          <div class="why-us-text">
            <p class="detail-3__sub-title">
              Access a first to market lending solution
            </p>
            <p style="color: rgba(1, 11, 50, 1); font-size: 16px">
              Replace invoice finance, term loan, overdrafts and other
              facilities into one automated solution. Select from a variety of
              repayment options
            </p>
          </div>
          <div class="why-us-text">
            <p class="detail-3__sub-title">
              Avoid delays in processing payments
            </p>
            <p style="color: rgba(1, 11, 50, 1); font-size: 16px">
              All payments are same day processing compared to standard
              transfers. Card solution enables you to pay larger amounts to
              suppliers
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="ceo-quote panel">
    <div class="container ceo-quote-container d-flex">
      <div style="margin: 0 auto; text-align: center">
        <h3 class="mb-50">
          <span style="color: rgb(18, 175, 230)"
            >See how your
            <span style="color: white">lending solution</span> works</span
          >
        </h3>
        <button type="submit" class="btn btn-orange mt-30" (click)="ToHowItWorks()">
          Learn More
        </button>
      </div>
    </div>
  </section>
</div>
