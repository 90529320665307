import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./components/home/home.component";
import { PartnerComponent } from "./components/partner/partner.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FaqComponent } from "./components/faq/faq.component";
import { HttpClientModule } from "@angular/common/http";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { TermsComponent } from "./components/terms/terms.component";
import { OurStoryComponent } from "./components/our-story/our-story.component";
import { AccreditationComponent } from "./components/accreditation/accreditation.component";
import { Router, RouterModule } from "@angular/router";
import { OriginationReadApi } from "./core/proxy/Read/originationReadApi";
import { OriginationWriteApi } from "./core/proxy/Write/originationWriteApi";
import { TokenHolderService } from "./core/services/token-holder.service";
import { AuthorizationAPI } from "./core/proxy/Auth/authorizationAPI";
import { ReportingServiceAPI } from "./core/proxy/Report/reportingServiceAPI";
import { InterceptorHttpClient } from "./core/http/InterceptorHttpClient";
import { NumericDirective } from "./shared/directives/onlyNumber.directive";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HowItWorksComponent } from "./components/how-it-works/how-it-works.component";
import { ErrorHandlerService } from "./shared/ErrorHandler";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PhoneMask } from "./shared/directives/phone-mask.directive";
import { GetStartedComponent } from './components/get-started/get-started.component';
import { FundingHeroComponent } from "./components/get-started/funding-hero/funding-hero.component";
import { UsPhoneMaskDirective } from './shared/directives/us-phone-mask.directive';
import { UpstreamTaxPartnersComponent } from "./components/get-started/upstream-tax-partners/upstream-tax-partners.component";
import { PartnerReferralComponent } from './components/partner-referral/partner-referral.component';
import { EncoreComponent } from "./components/get-started/encore/encore.component";
import { MyMoneyEduComponent } from "./components/get-started/my-money-edu/my-money-edu.component";
import { QuickbooksComponent } from './components/quickbooks/quickbooks.component';
import { QuickBooksDisconnectedComponent } from './components/quick-books-disconnected/quick-books-disconnected.component';
import { QuickBooksLearnMoreComponent } from "./components/quick-books-learn-more/quick-books-learn-more.component";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: 1,
};

const providers: any[] = [
  {
    provide: OriginationReadApi,
    useFactory: OriginationReadApiFactory,
    deps: [TokenHolderService, Router],
  },
  { provide: ErrorHandler, useClass: ErrorHandlerService },
  {
    provide: OriginationWriteApi,
    useFactory: OriginationWriteApiFactory,
    deps: [TokenHolderService, Router],
  },
  {
    provide: AuthorizationAPI,
    useFactory: AuthorizationApiFactory,
    deps: [TokenHolderService, Router],
  },
  {
    provide: ReportingServiceAPI,
    useFactory: ReportingServiceApiFactory,
    deps: [TokenHolderService, Router],
  },
  {
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG,
  },
];
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PartnerComponent,
    AboutUsComponent,
    FaqComponent,
    PrivacyComponent,
    TermsComponent,
    OurStoryComponent,
    AccreditationComponent,
    NumericDirective,
    HowItWorksComponent,
    GetStartedComponent,
    FundingHeroComponent,
    PhoneMask,
    UpstreamTaxPartnersComponent,
    UsPhoneMaskDirective,
    EncoreComponent,
    MyMoneyEduComponent,
    PartnerReferralComponent,
    QuickbooksComponent,
    QuickBooksDisconnectedComponent,
    QuickBooksLearnMoreComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SwiperModule,
    CoreModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule,
    FontAwesomeModule,
  ],
  exports: [NumericDirective,UsPhoneMaskDirective, PhoneMask],
  providers: providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
export function AuthorizationApiFactory(
  tokenHolder: TokenHolderService,
  router: Router
) {
  return new AuthorizationAPI(tokenHolder.getToken(), {
    baseUri: window["appConfig"]["authApiUrl"],
    httpClient: new InterceptorHttpClient(router),
  });
}

export function OriginationReadApiFactory(
  tokenHolder: TokenHolderService,
  router: Router
) {
  return new OriginationReadApi(tokenHolder.getToken(), {
    baseUri: window["appConfig"]["readApiUrl"],
    httpClient: new InterceptorHttpClient(router),
  });
}

export function OriginationWriteApiFactory(
  tokenHolder: TokenHolderService,
  router: Router
) {
  return new OriginationWriteApi(tokenHolder.getToken(), {
    baseUri: window["appConfig"]["writeApiUrl"],
    httpClient: new InterceptorHttpClient(router),
  });
}

export function ReportingServiceApiFactory(
  tokenHolder: TokenHolderService,
  router: Router
) {
  return new ReportingServiceAPI(tokenHolder.getToken(), {
    baseUri: window["appConfig"]["reportingApiUrl"],
    httpClient: new InterceptorHttpClient(router),
  });
}
