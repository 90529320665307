<div class="au-how-it-works-section">
  <section class="how-it-works">
    <div class="how-it-works-container">
      <h1 class="how-it-works__header white">How it works</h1>
      <span class="header-divider"></span>
      <h4 class="how-it-work__subtitle mb-60">A fast and simple process</h4>
      <p class="how-it-work__text mb-30">
        Access funds 24 hours, 7 days a week. Explore the different repayment
        options available to you. If you choose to proceed, your account set up
        is completed in minutes.
      </p>
      <p class="how-it-work__text">
        Connect your account software and/or bank account details to find out
        your maximum borrowing capacity immediately. There are no credit checks
        or application forms. We do not store your login credentials.
      </p>
      <div class="how-it-work__find-more" (click)="scrollToElement(target)">
        <a class="find-more-btn">Find out more</a>
        <i class="far fa-arrow-alt-circle-down find-out-more"></i>
      </div>
    </div>
  </section>
  <section class="detail">
    <div class="detail-container">
      <h3 class="detail__title">
        Your guide to <span class="detail__span-text">our line of credit</span>
      </h3>
      <span class="detail__divider"></span>
      <p class="detail__subtitle">How could your business benefit</p>
      <div class="detail__details-list">
        <div class="detail-list__item">
          Most working capital loans require you to withdraw 100% of available
          funds once you’re approved and reapply each time you need additional
          funds for your business. This leaves you with little funds available
          when unexpected opportunities arise.
        </div>
        <div class="detail-list__item">
          With Plexe your Line of Credit provides you with a limit, which is the
          maximum amount you can withdraw. You can use it as much or little, and
          as often as you like for business purchases such as inventory,
          supplies, or operating expenses.
        </div>
        <div class="detail-list__item">
          Each time you withdraw you set the terms you like, which consolidates
          all your past withdrawals. This makes it easy for you to manage only
          one schedule and budget accordingly.
        </div>
        <div class="detail-list__item">
          We also recognise that business can face situations that affect cash
          flow. This can make it particularly difficult when you have fixed loan
          repayments. That is why we let you choose between fixed or percentage
          of your weekly income repayments. Over the time you can even switch
          between the two to perfectly match your needs.
        </div>
      </div>
    </div>
  </section>
  <section class="why-us detail-4 panel" #target>
    <div class="container why-us-container d-flex">
      <p class="" style="text-align: center">
        <img src="../../../assets/svgs/mail.svg" alt="mail" />
      </p>
      <h3 class="why-us__subtitle">Want to learn more?</h3>
      <span class="detail__divider"></span>
      <p
        id="hero-text-2"
        class="hero__text animate__animated animate__fadeIn mb-30"
      >
        Leave your details and we will get in touch to help you find a solution
        that meets your needs.
      </p>
      <form
        class="form"
        style="margin: 0 auto"
        [formGroup]="pendingPartnerDetailForm"
        (submit)="submitPendingPartnerDetail()"
      >
        <div class="form-group" style="margin-bottom: 0">
          <input
            type="text"
            formControlName="firstname"
            class="form-input mt-10"
            placeholder="First name"
            required
          />
          <input
            type="text"
            formControlName="lastname"
            class="form-input mt-10"
            placeholder="Last name"
            required
          />
        </div>

        <input
          type="email"
          formControlName="email"
          class="form-input mt-10"
          placeholder="Email address"
          email="true"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          required
        />

        <input
          type="tel"
          formControlName="phone"
          inputmode="tel"
          class="form-input mt-10"
          placeholder="(___) ___-____"
          maxlength="14"
          pattern="[0-9]+"
          required
        />

        <input
          type="text"
          formControlName="company"
          class="form-input mt-10"
          placeholder="Company"
          required
        />

        <button
          type="submit"
          [disabled]="pendingPartner"
          class="btn btn-orange mt-40"
          style="margin: 0 auto; margin-top: 30px"
        >
          Submit
        </button>
      </form>
    </div>
  </section>
</div>
