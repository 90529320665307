import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { ApiService } from "../../../shared/services/api.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { elementAt, finalize } from "rxjs/operators";
import { UrlService } from "../../../shared/services/url.service";

@Component({
  selector: "app-upstream-tax-partners",
  templateUrl: "./upstream-tax-partners.component.html",
  styleUrls: ["./upstream-tax-partners.component.scss"],
})
export class UpstreamTaxPartnersComponent implements OnInit {
  partnerDetailSentSuccess = false;
  tradingYears = ['0-1 years', '1-2 years', '2-5 years', 'More than 5 years'];
  public howitworks: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: 1,
    keyboard: true,
    navigation: {
      nextEl: ".slider-control-next",
      prevEl: ".slider-control-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      bulletClass: "indicator",
      bulletActiveClass: "indicator_selected",
      clickable: true,
    },
    loop: true,
    autoHeight: true,
  };

  public guide: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: 1,
    keyboard: true,
    navigation: {
      nextEl: ".slider-control-next_guide",
      prevEl: ".slider-control-prev_guide",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      bulletClass: "indicator",
      bulletActiveClass: "indicator_selected",
      clickable: true,
    },
    loop: true,
    autoHeight: true,
  };

  howItWorksCurrentSlide = 0;

  guideCurrentSlide = 0;

  shareDetailsForm: FormGroup;
  sharePartnerDetailsForm: FormGroup;

  pendingShareDetails = false;
  heroHeaderText;
  headers = ["Supporting your business every step of the way"];
  heroSecondaryText;
  herotexts = [
    "Lending that provides you with continuously updated, individual repayment terms and funding limits",
  ];

  colorClassesNames = [
    "color",
    "color-white",
    "color",
    "color",
    "color-blue",
    "color-orange",
    "color-white-gray",
  ];
  registerForm: FormGroup;

  showLaunchPanel = this.urlService.checkUrlCo();

  applicationPortalSite ;
  loadFromParams = false;
  pendingRegisterForm = false;
  id = "";

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (!this.urlService.checkUrlCo()) {
      const panels = document.getElementsByClassName("panel");
      const scroll = window.scrollY + window.innerHeight / 1.5;

      for (let i = 0; i < panels.length; i++) {
        const panel = panels[i] as HTMLElement;

        if (
          panel.offsetTop <= scroll &&
          panel.offsetTop + panel.offsetHeight > scroll
        ) {
          for (let j = 0; j < panels.length; j++) {
            this.colorClassesNames.map((className) =>
              panels[j].classList.remove(className)
            );
            panels[j].classList.add(this.colorClassesNames[i]);
          }
        }
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    public urlService: UrlService,
    private router: Router
  ) {
  this.applicationPortalSite = window["appConfig"]["applicationPortalSite"];
    this.route.queryParams.subscribe((params) => {
      this.id = params.utm_term ? params.utm_term : "";

    });
  }

  ngOnInit(): void {
    this.initRegisterForm();
    if (this.urlService.checkUrlCom()) {
      this.initShareDetailsForm();
      this.heroHeaderText = this.headers[0];
      this.heroSecondaryText = this.herotexts[0];
      const heroHeaderWrapperElement = document.getElementById(
        "hero-header-wrapper"
      );
      if (heroHeaderWrapperElement) {
        heroHeaderWrapperElement.style.maxWidth = 700 + "px";
      }
    } else {
      this.initSharePartnerDetailsForm();
    }
  }

  private initShareDetailsForm() {
    this.shareDetailsForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: ["", [Validators.required]],
      companyName: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
    });
  }

  private initSharePartnerDetailsForm() {
    this.sharePartnerDetailsForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
      yearsTrading: ["", [Validators.required]],
      zipcode: ["", [Validators.required]],
    });
  }

  initRegisterForm() {
    this.registerForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  shareDetails() {
    this.pendingShareDetails = true;
    if (this.shareDetailsForm.valid) {
      this.apiService
        .sendMessage("LearnMore", this.shareDetailsForm.value)
        .pipe(finalize(() => (this.pendingShareDetails = false)))
        .subscribe(
          (data) => {
            this.toastr.success("Your details has been sent");
          },
          (error) => {
            this.toastr.error("Server Error. Try again later");
          }
        );
    } else {
      this.toastr.error("Form is invalid. Enter correct information");
      this.pendingShareDetails = false;
    }
  }

  sharePartnerDetails() {
    this.pendingShareDetails = true;
    if (this.sharePartnerDetailsForm.valid) {
      this.apiService
        .sendMessage("Enqury", this.sharePartnerDetailsForm.value)
        .pipe(finalize(() => (this.pendingShareDetails = false)))
        .subscribe(
          (data) => {
            this.toastr.success("Your details has been sent");
            this.partnerDetailSentSuccess = true;
            setTimeout(function () {
              this.partnerDetailSentSuccess = false;
            }, 500);
            this.sharePartnerDetailsForm.reset();
          },
          (error) => {
            this.toastr.error("Server Error. Try again later");
            this.sharePartnerDetailsForm.reset();
          }
        );
    } else {
      this.toastr.error("Form is invalid. Enter correct information");
      this.pendingShareDetails = false;
    }
  }

  register() {
    this.pendingRegisterForm = true;
    if (this.sharePartnerDetailsForm.valid) {
      var heroId = window["appConfig"]["upstreamTaxPartners"];
      this.apiService.verifyCodeWithPartner(
        this.applicationPortalSite,
        this.sharePartnerDetailsForm.controls["firstName"].value,
        this.sharePartnerDetailsForm.controls["phoneNumber"].value,
        this.sharePartnerDetailsForm.controls["email"].value,
        this.sharePartnerDetailsForm.controls["password"].value,
        this.sharePartnerDetailsForm.controls["yearsTrading"].value,
        this.sharePartnerDetailsForm.controls["zipcode"].value,
        heroId,
        this.id
      );
    } else {
      if (this.sharePartnerDetailsForm.controls["firstName"].invalid) {
        this.toastr.error("Name is required");
      }
      if (this.sharePartnerDetailsForm.controls["phoneNumber"].invalid) {
        this.toastr.error("Phone is required");
      }
      if (this.sharePartnerDetailsForm.controls["email"].invalid) {
        this.toastr.error("Email address is invalid");
      }
      if (this.sharePartnerDetailsForm.controls["zipcode"].invalid) {
        this.toastr.error("Zip Code is invalid");
      }
      if (this.sharePartnerDetailsForm.controls["yearsTrading"].invalid) {
        this.toastr.error("Years in Business is invalid");
      }
      if (this.sharePartnerDetailsForm.controls["password"].invalid) {
        this.toastr.error(
          "Password is invalid. Password must contain minimum 8 characters, including 1 digit, 1 uppercase & lowercase letter."
        );
      }
      this.pendingRegisterForm = false;
    }
  }

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }

  ToFaq() {
    this.router.navigate(["/faq"]);
  }

  ToHome() {
    this.router.navigate(["/our-story"]);
  }

  ToHowItWorks() {
    this.router.navigate(["/how-it-works"]);
  }
}
