<header class="header-container position-fixed">
  <div class="container" [ngClass]="{ 'container-usdesign': true }">
    <nav class="navbar navbar_desktop">
      <a routerLink="">
        <svg class="logo" xmlns="http://www.w3.org/2000/svg" width="125" height="51">
          <path fill="#12AFE6"
            d="M16.9 10.4c-4.3 0-7.6 1.9-8.9 4.2v-3.5H.4v39.8h7.8V37.1c1.4 1.9 4.3 3.5 8.3 3.5 8.2 0 13.6-6.5 13.6-15.2.1-8.5-4.8-15-13.2-15zm-1.5 23.3c-4 0-7.2-3.1-7.2-8.2s3.2-8.1 7.2-8.1 7.2 3 7.2 8.1c-.1 5.2-3.2 8.2-7.2 8.2zM32.9.1h7.8v39.8h-7.8zM64.9 31.2c-1.3 1.4-3.2 3-6.6 3-3.9 0-7.1-2.8-7.3-5.8h20.7c0-.9 1.9-18.1-14.2-18.1-7.4 0-14.2 6-14.2 15.2 0 9.7 7 15.4 14.9 15.4 5.5 0 9.5-2.5 11.7-5.3l-5-4.4zm-7.2-14.7c4.5 0 6.3 2.8 6.5 6.1h-13c.2-3 2.4-6.1 6.5-6.1zM110.3 10.2c-7.4 0-14.2 6-14.2 15.2 0 9.7 7 15.4 14.9 15.4 5.5 0 9.5-2.5 11.7-5.3l-5.1-4.3c-1.3 1.4-3.2 3-6.6 3-3.9 0-7.1-2.8-7.3-5.8h20.7c.1-1 2-18.2-14.1-18.2zM104 22.7c.2-3.1 2.4-6.1 6.5-6.1 4.5 0 6.3 2.8 6.5 6.1h-13z" />
          <path fill="#12AFE6" d="M73.5 11.1h-1.3l10.3 14.4-10.3 14.4h1.3l10.3-14.4z" />
          <path fill="#12AFE6" d="M76.1 11.1h-1.3l10.3 14.4-10.3 14.4h1.3l10.3-14.4z" />
          <path fill="#12AFE6" d="M70.9 11.1h-1.3l10.3 14.4-10.4 14.4h1.4l10.3-14.4z" />
          <path fill="#12AFE6" d="M99.3 11.1h-9.1l-5.7 8-5.8-8h-1.3l10.3 14.4-10.3 14.4h1.3l5.8-8 5.7 8h9.2L89 25.5z" />
        </svg>
      </a>

      <div class="collapse navbar-collapse">
        <ul class="navbar-nav align-items-center">
          <li class="nav-item nav-item_first">
            <a class="nav-link text-uppercase" routerLink="" [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-uppercase" routerLink="how-it-works" [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active">How it works</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-uppercase" routerLink="our-story" [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active">Our story</a>
          </li>
          <li class="nav-item" *ngIf="urlService.checkUrlCom()">
            <a class="nav-link text-uppercase" routerLink="faq" [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active">FAQ's</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-uppercase" routerLink="partner" [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active">Partner</a>
          </li>
          <!--  <li class="nav-item" *ngIf="!hideAccreditation">
            <a
              class="nav-link text-uppercase"
              routerLink="accreditation"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active"
              >Accreditation</a
            >
          </li>-->
        </ul>
        <ul class="navbar-nav align-items-center ml-auto">
          <li class="nav-item" *ngIf="urlService.checkUrlCom()">
            <a href="javascript:void(0)" [routerLink]="[isPartner() ? '/partner/' : '/']" fragment="getstarted"
              class="btn btn-orange">Get started</a>
            <!-- <a href="https://portal.plexe.com.au/public" class="btn btn-orange">Get started</a> -->
          </li>
          <li class="nav-item" *ngIf="urlService.checkUrlCo()">
            <a href="javascript:void(0)" [routerLink]="[isPartner() ? '/partner/' : '/']" fragment="getstarted"
              class="btn btn-orange">Get started</a>
            <!-- <a href="https://portal.plexe.co/public" class="btn btn-orange"
              >Get started</a
            > -->
          </li>
          <li class="nav-item nav-item_last" *ngIf="urlService.checkUrlCom()">
            <a href="https://portal.plexe.com.au/login" class="btn btn-lightblue btn-outline-light">Login</a>
          </li>
          <li class="nav-item nav-item_last" *ngIf="urlService.checkUrlCo()">
            <a href="https://portal.plexe.co/login" class="btn btn-lightblue btn-outline-light">Login</a>
          </li>
        </ul>
      </div>

      <button class="navbar-toggler" type="button" (click)="showNav()">
        <svg width="34" height="22" viewBox="0 0 14 10" fill="#2B2B2B">
          <rect width="34" height="2" x="0" y="0"></rect>
          <rect width="34" height="2" x="0" y="4"></rect>
          <rect width="34" height="2" x="0" y="8"></rect>
        </svg>
      </button>
    </nav>
  </div>
</header>
<div class="navbar_mobile" [class.showNav]="isHidden">
  <span class="navbar_mobile-close" (click)="isHidden = false">╳</span>

  <ul class="navbar_mobile-list">
    <li class="nav-item pt-3">
      <a class="nav-link" routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        (click)="isHidden = false">Home</a>
    </li>
    <li class="nav-item pt-3">
      <a class="nav-link" routerLink="how-it-works" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active" (click)="isHidden = false">How it works</a>
    </li>

    <li class="nav-item pt-3">
      <a class="nav-link" routerLink="our-story" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
        (click)="isHidden = false">Our story</a>
    </li>

    <!-- <li class="nav-item pt-3">
      <a
        class="nav-link"
        routerLink="faq"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        (click)="isHidden = false"
        >FAQ's</a
      >
    </li> -->

    <li class="nav-item pt-3">
      <a class="nav-link" routerLink="partner" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
        (click)="isHidden = false">Partner</a>
    </li>

    <li class="nav-item pt-3" *ngIf="!hideAccreditation">
      <a class="nav-link" routerLink="accreditation" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active" (click)="isHidden = false">Accreditation</a>
    </li>

    <li class="nav-item mt-2" *ngIf="urlService.checkUrlCom()">
      <!-- <a href="https://portal.plexe.com.au/public" class="btn btn-orange">Get started</a> -->
      <a href="javascript:void(0)" [routerLink]="[isPartner() ? '/partner/' : '/']" fragment="getstarted"
        class="btn btn-orange">Get started</a>
    </li>

    <li class="nav-item mt-2" *ngIf="urlService.checkUrlCom()">
      <a href="https://portal.plexe.com.au/login" class="btn btn-lightblue btn-outline-light">Login</a>
    </li>
  </ul>
</div>