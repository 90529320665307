import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router) { }

  sendMessage(messageType: string, message: { [key: string]: string }) {
    var jsonString = JSON.stringify(message);

    var site = window["appConfig"]["writeApiUrl"];
    return this.http.post(
      `${site}/api/Application/send-message?messageType=${messageType}`,
      { jsonString }
    );
  }

  verifyCode(
    customerPortalSite: string,
    name,
    number,
    email,
    password,
    years,
    zipcode
  ) {
    const partnerId = window["appConfig"]["naturalIntelligence"];
    let params = new HttpParams()
      .append("name", name)
      .append("number", number.replace("(", "").replace("-", "").replace(")", "").replace(" ", ""))
      .append("email", email)
      .append("password", btoa(password))
      .append("years", years)
      .append("zipcode", zipcode);
    if (this.router.url.includes("natural-intelligence")) {
      params.append("partnerId", partnerId);
      window.document.location.href = `${customerPortalSite}/public/new-application/code-verify?${params.toString()}`;
    } else {
      window.document.location.href = `${customerPortalSite}/public/new-application/code-verify?${params.toString()}`;
    }

    //ADD EXTRA SUBID TO BE PASSED IN
    //ADD WHAT page cam i on...naturalintellgence page or default
    //if naturalintellgence get guid from config create new param called "partnerId"
  }

  verifyCodeWithPartner(
    customerPortalSite: string,
    name,
    number: string,
    email,
    password,
    years,
    zipcode,
    partnerId,
    subid
  ) {
    let params = new HttpParams()
      .append("name", name)
      .append("number", number.replace("(", "").replace("-", "").replace(")", "").replace(" ", ""))
      .append("email", email)
      .append("password", btoa(password))
      .append("years", years)
      .append("zipcode", zipcode)
      .append("partnerId", partnerId)
       .append("subid", subid);
     
    window.document.location.href = `${customerPortalSite}/public/new-application/code-verify?${params.toString()}`;
  }

  redirect(
    customerPortalSite: string,
  ) {

    window.document.location.href = `${customerPortalSite}/public/new-application`;
  }
}
