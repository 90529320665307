<div class="au-quick-books-learn-more-section">
  <section class="quick-books-learn-more">
    <div class="quick-books-learn-more-container">
      <h1 class="quick-books-learn-more__header white">Intuit QuickBooks + Plexe</h1>
      <span class="header-divider"></span>
      <!--<h4 class="quick-books-learn-more__subtitle mb-60">A fast and simple process</h4>-->
      <p class="quick-books-learn-more__text mb-30">
        QuickBooks data helps streamline the application process from days to minutes
        Effortlessly connect your QuickBooks data to get fast approval for access to a modern revolving business line of credit
      </p>
      <p class="quick-books-learn-more__text">
        Plexe, a leading provider of everyday financing to small business and on a mission to change the way they manage their finances for the better, is pleased to confirm its partnership with Intuit Inc, available on the QuickBooks platform. 
      </p>
      <p class="quick-books-learn-more__text">
        The new partnership will enable small businesses to create a free account and for qualified businesses, receive tailored funding solutions, including access to a revolving line-of-credit that can reset the previous terms on each draw,  flexible duration terms and various repayment options. Through proprietary analytics, businesses receive the benefit of limits that automatically adjust real time based on business performance, thereby ensuring funds are available when needed. 
       </p>
       <p class="quick-books-learn-more__text">
        QuickBooks is a powerful accounting software package that makes it easier to manage small businesses and helps streamline the registration process. Effortlessly connect your QuickBooks data for qualified businesses to receive access to a variety of products and solutions.
       </p>
      <div class="quick-books-learn-more__find-more">
        <a class="find-more-btn" *ngIf="urlService.checkUrlCom()" href="https://portal.plexe.com.au/login">Log In</a>
        <a class="find-more-btn" *ngIf="urlService.checkUrlCo()" href="https://portal.plexe.co/login">Log In</a>
        <i class="far fa-arrow-alt-circle-down find-out-more"></i>
      </div>
    </div>
  </section>
  <section class="detail">
    <div class="detail-container">
      <h3 class="detail__title">
        Your guide to <span class="detail__span-text">our line of credit</span>
      </h3>
      <span class="detail__divider"></span>
      <p class="detail__subtitle">How could your business benefit</p>
      <div class="detail__details-list">
        <div class="detail-list__item">
          Most working capital loans require you to withdraw 100% of available
          funds once you’re approved and reapply each time you need additional
          funds for your business. This leaves you with little funds available
          when unexpected opportunities arise.
        </div>
        <div class="detail-list__item">
          With Plexe your Line of Credit provides you with a limit, which is the
          maximum amount you can withdraw. You can use it as much or little, and
          as often as you like for business purchases such as inventory,
          supplies, or operating expenses.
        </div>
        <div class="detail-list__item">
          Each time you withdraw you set the terms you like, which consolidates
          all your past withdrawals. This makes it easy for you to manage only
          one schedule and budget accordingly.
        </div>
        <div class="detail-list__item">
          We also recognise that business can face situations that affect cash
          flow. This can make it particularly difficult when you have fixed loan
          repayments. That is why we let you choose between fixed or percentage
          of your weekly income repayments. Over the time you can even switch
          between the two to perfectly match your needs.
        </div>
      </div>
    </div>
  </section>
  <section class="why-us detail-4 panel" #target>
    <div class="container why-us-container d-flex">
      <p class="" style="text-align: center">
        <img src="../../../assets/svgs/mail.svg" alt="mail" />
      </p>
      <h3 class="why-us__subtitle">Want to learn more?</h3>
      <span class="detail__divider"></span>
      <p id="hero-text-2"
         class="hero__text animate__animated animate__fadeIn mb-30">
        Leave your details and we will get in touch to help you find a solution
        that meets your needs.
      </p>
    </div>
  </section>
</div>
